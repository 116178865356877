import React, { useState } from "react";
import axios from "axios";
import "./Register.css";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import logo from "./assets/img/logo/cutis-logo.png";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetValueLogout } from "../actions/Index";

const Register = (props) => {
  const myState = useSelector((state) => state.changeTheNumber);
  const dispatch = useDispatch();

  const getDocotr = JSON.parse(myState[0]);
  const uri = `${process.env.REACT_APP_URL}`;
  const getdoctorID = props.match.params.doctorid;
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const userMobileNumber = myState[3];

  if (typeof userMobileNumber !== "number") {
    window.history.back();
  }

  const [userAge, setuserAge] = useState("");
  const [districtList, setdistrictList] = useState(null);
  const [areaList, setareaList] = useState(null);
  const [dob, setdob] = useState("");
  const [todayDate, settodayDate] = useState("initialState");
  const [referalSource, setreferalSource] = useState(null);

  const handleReferalClick = () => {
    if (referalSource === null) {
      axios
        .get(`${uri}/Patient/GetReferralSource`)
        .then(({ data }) => {
          setreferalSource(JSON.parse(data.Data));
        })
        .catch((err) => {
          console.warn(err);
        });
    } else {
      return referalSource;
    }
  };

  React.useEffect(() => {
    axios
      .get(`${uri}/Patient/GetDistrict`)
      .then(({ data }) => {
        setdistrictList(JSON.parse(data.Data));
      })
      .catch((err) => {
        console.warn(err);
      });
    axios
      .get(`${uri}/Patient/GetReferralSource`)
      .then(({ data }) => {
        setreferalSource(JSON.parse(data.Data));
      })
      .catch((err) => {
        console.warn(err);
      });
    axios
      .get(`${uri}/Patient/GetArea`)
      .then(({ data }) => {
        setareaList(JSON.parse(data.Data));
      })
      .catch((err) => {
        console.warn(err);
      });
    const tempDate = new Date();
    let [date, month, year] = [
      tempDate.getDate(),
      tempDate.getMonth() + 1,
      tempDate.getFullYear() + 1,
    ];
    date = date < 10 ? "0" + date : date;
    month = month < 10 ? "0" + month : month;
    let dateStr = year + "-" + month + "-" + date;
    settodayDate(dateStr);
  }, []);

  const handleChangeDOB = (e) => {
    let tempDate = new Date(e.target.value);
    let [date, month, year] = [
      tempDate.getDate(),
      tempDate.getMonth() + 1,
      tempDate.getFullYear(),
    ];
    date = date < 10 ? "0" + date : date;
    month = month < 10 ? "0" + month : month;
    let dateStr = year + "-" + month + "-" + date;
    setdob(dateStr);
    if (dateStr.length === 10) {
      let todayDate = new Date();
      let currentAge = todayDate.getFullYear() - tempDate.getFullYear();
      let mnth = todayDate.getMonth() - tempDate.getMonth();
      if (
        mnth < 0 ||
        (mnth === 0 && todayDate.getDate() < tempDate.getDate())
      ) {
        currentAge--;
      }
      setuserAge(`${currentAge} years`);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const myVal = e.target[2].value;
      let temp;
      temp = myVal === "M" ? 1 : 3;
      let myMaritalStatus = e.target[3].value;
      if (myMaritalStatus === "Select") {
        myMaritalStatus = "";
      }
      let areaUser = e.target[9].value;
      if (areaUser === "Select area") {
        areaUser = "";
      }
      let stateUser = e.target[7].value;
      if (stateUser === "Select state") {
        stateUser = "";
      }
      let districtUser = e.target[8].value;
      if (districtUser === "Select district") {
        districtUser = "";
      }
      let referalUser = e.target[13].value;
      if (referalUser === "Select Referal") {
        alert(`Referal field is required`);
        return false;
      }
      let genderUser = e.target[2].value;
      if (genderUser === "Select your Gender") {
        alert(`Gender field is required`);
        return false;
      }
      axios
        .post(
          `${uri}/Patient/PatientRegistration?PatientData=[{"MobileNo": "${userMobileNumber}",
        "Hospital_id":1,
        "FName": "${e.target[0].value}",
        "LName": "${e.target[1].value}",
        "Age":"${userAge}",
        "Gender": "${e.target[2].value}",
        "Salutation" : "${temp}",
        "DOB": "${dob}",
        "Validate_Date":"${todayDate}",
        "MaritalStatus": "${myMaritalStatus}",
        "EmailId": "${e.target[6].value}",
        "Address1": "${e.target[10].value}",
        "Address2": "${e.target[11].value}",
        "PinCode": "${e.target[12].value}",
        "Area": "${areaUser}",
        "District":"${districtUser}",
        "State":"${stateUser}",
        "Consultantid":"${getDocotr[0].ID}",
        "RefId":"0",
        "ReferralSource":"${referalUser}"}]`
        )
        .then(({ data }) => {
          if (data.Message === "Created Successfully") {
            history.push(`/step-six/doctorid/${getdoctorID}`);
          } else {
            alert(`We have some technical issue`);
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    }
    setValidated(true);
    e.preventDefault();
  };
  const outMe = [];
  const logMeOut = () => {
    dispatch(resetValueLogout(outMe));
    sessionStorage.clear();
    history.push(`/`);
  };
  return (
    <div id="main-div-fluid">
      {/* ----------------upper strip---------------- */}
      <Container fluid id="upper-strip">
        <Container className="py-4 pb-md-0 pb-3">
          <Row>
            <Col md={6} id="doctor-detail" className="d-flex">
              <div className="text-white mb-3">
                <img src={logo} alt="na" />
              </div>
            </Col>
            <Col md={6} id="back-button">
              <button
                className="btn btn-light rounded-pill px-3 px-md-4 float-right mb-2 ml-3"
                onClick={logMeOut}
              >
                <i className="fa fa-power-off" aria-hidden="true"></i>
              </button>
              <button
                className="btn btn-light rounded-pill px-3 px-md-4 float-md-right mb-2"
                onClick={history.goBack}
              >
                Back
              </button>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* -------------------end upper strip------------ */}
      <div className="container text-white mt-2">
        <div className=" px-md-5 py-md-4 p-2 bgme-trans">
          <div className="form-heading text-center text-uppercase pb-sm-4 pb-2">
            <h2 className="bg-success d-md-inline p-2 text-white rounded shadow">
              Register Here
            </h2>
          </div>
          <Container>
            <Row>
              <Col>
                <div className="offset-sm-1 offset-md-2">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Row>
                      <Form.Group as={Col} sm="10" md="5">
                        <Form.Label>
                          First Name<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Your first name"
                          pattern="[a-zA-Z0-9 ]{2,100}"
                          name="FirstName"
                          // onChange={handleChangeFname}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid Name.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} sm="10" md="5">
                        <Form.Label>
                          Last Name<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Your last name"
                          pattern="[a-zA-Z ]{1,100}"
                          name="LastName"
                          // onChange={handleChangeLname}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid Name.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} sm="10" md="5">
                        <Form.Label>
                          Gender<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          id="Gender"
                          name="Gender"
                          required
                        >
                          {/* <option value="" disabled>
                            Select your Gender
                          </option> */}
                          <option disabled selected hidden>
                            Select your Gender
                          </option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} sm="10" md="5">
                        <Form.Label>Marital Status</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          name="MaritalStatus"
                          defaultValue={`Select`}
                          // onChange={handleChangeMStatus}
                        >
                          <option disabled defaultValue={`Select`}>
                            Select
                          </option>
                          <option value="S">Married</option>
                          <option value="N">Unmarried</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} sm="10" md="5">
                        <Form.Label>
                          Date of Birth<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Your age"
                          id="DOB"
                          name="DOB"
                          onChange={handleChangeDOB}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your Date of Birth.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} sm="10" md="5">
                        <Form.Label>
                          Mobile Number<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Your contact number"
                          pattern="[6-9]{1}[0-9]{9}"
                          required
                          id="MobileNumber"
                          name="MobileNumber"
                          value={userMobileNumber}
                          disabled
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide your Mobile number.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group as={Col} sm="10" md="5">
                        <Form.Label>
                          Email<span className="text-danger">*</span>
                        </Form.Label>
                        <InputGroup hasValidation>
                          <Form.Control
                            type="email"
                            placeholder="Your email ID"
                            aria-describedby="inputGroupPrepend"
                            id="Email"
                            name="Email"
                            required
                            // onChange={handleChangeEmail}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter your mail.
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group as={Col} sm="10" md="5">
                        <Form.Label>State</Form.Label>

                        <Form.Control
                          as="select"
                          custom
                          id="State"
                          name="State"
                          defaultValue={`Select state`}
                        >
                          <option disabled defaultValue={`Select state`}>
                            Select state
                          </option>
                          <option value="1">karnataka</option>
                          <option value="2">Andhra Pradesh</option>
                          <option value="3">Punjab</option>
                          <option value="4">Delhi </option>
                          <option value="5">Maharashtra </option>
                          <option value="6">Tamilnadu </option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} sm="10" md="5">
                        <Form.Label>District</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          id="District"
                          name="District"
                          defaultValue={`Select district`}
                        >
                          <option disabled defaultValue={`Select district`}>
                            Select district
                          </option>
                          {districtList !== null &&
                            districtList.map((value, index) => {
                              return (
                                <option value={value.ID} key={index}>
                                  {value.Name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} sm="10" md="5">
                        <Form.Label>Area</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          id="Area"
                          name="Area"
                          defaultValue={`Select area`}
                        >
                          <option disabled defaultValue={`Select area`}>
                            Select area
                          </option>
                          {areaList !== null &&
                            areaList.map((value, index) => {
                              return (
                                <option value={value.ID} key={index}>
                                  {value.Name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        sm="10"
                        // md="5"
                      >
                        <Form.Label>
                          Address 1<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder="Address1"
                          pattern="[a-zA-Z0-9-,/()# ]{2,254}"
                          name="Address1"
                          // onChange={handleChangeAddOne}
                          // value={user.Address1}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid Address.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        as={Col}
                        sm="10"
                        // md="5"
                      >
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Address2"
                          pattern="[a-zA-Z0-9-,/()# ]{2,254}"
                          name="Address2"
                          // onChange={handleChangeAddTwo}
                          // value={user.Address2}
                        />
                      </Form.Group>

                      <Form.Group as={Col} sm="10" md="5">
                        <Form.Label>
                          Pincode<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Pin code"
                          pattern="[0-9]{6}"
                          maxLength={6}
                          required
                          id="PinCode"
                          name="PinCode"
                          // onChange={handleChangePinCode}
                          // value={user.PinCode}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid PinCode.
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group as={Col} sm="10" md="5">
                        <Form.Label>
                          Referal<span className="text-danger">*</span>
                        </Form.Label>

                        <Form.Control
                          as="select"
                          custom
                          id="Referal"
                          name="Referal"
                          required
                          onClick={handleReferalClick}
                        >
                          <option disabled selected hidden>
                            Select Referal
                          </option>
                          {referalSource !== null &&
                            referalSource.map((value, index) => {
                              return (
                                <option value={value.ID} key={index}>
                                  {value.Name}
                                </option>
                              );
                            })}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid PinCode.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>

                    <div className="offset-sm-4">
                      <Button
                        type="submit"
                        className="btn btn-success btn-lg m-2"
                      >
                        Submit form
                      </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Register;
