import React, { useState, useEffect } from "react";
import "./StepThird.css";
import { Container, Col, Row } from "react-bootstrap";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import drimg from "./assets/img/background/logo.png";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
const StepThird = (props) => {
  const myState = useSelector((state) => state.changeTheNumber);
  const history = useHistory();
  const getDocotr = JSON.parse(myState[0]);
  const getdoctorID = props.match.params.doctorid;
  const [doctorname, setDoctorname] = useState([]);
  const [loading, SetLoading] = useState(true);
  const uri = `${process.env.REACT_APP_URL}`;
  useEffect(() => {
    axios
      .get(
        `${uri}/Doctor/GetDoctorProfile?DoctorId=${getdoctorID}&hospitalid=1`
      )
      .then(({ data }) => {
        const temp = JSON.parse(data.Data);
        // dispatch(doctorDetails(JSON.stringify(temp)));
        setDoctorname(temp);
        SetLoading(false);
        // console.log(JSON.stringify(temp));
      })
      .catch((err) => {
        console.warn(err);
      });
  }, []);
  // console.log(doctorname);

  return (
    <>
      {loading ? (
        ""
      ) : (
        <>
          <div id="main-div-fluid">
            {/* ----------------upper strip---------------- */}
            <Container fluid id="upper-strip">
              <Container className="pt-4 pb-md-0 pb-3">
                <Row>
                  <Col md={6} id="doctor-detail" className="d-flex">
                    {/* <div className="mx-2 my-1  step-third-icon">
                <i className="fas fa-plus"></i>
              </div> */}
                    <AddCircleIcon id="step-third-icon" />
                    <div className="text-white">
                      <h4> {doctorname && doctorname[0].Name}</h4>
                      <p>
                        {doctorname && doctorname[0].Specialisation}&nbsp;&
                        <br />
                        {doctorname && doctorname[0].Designation}
                      </p>
                    </div>
                  </Col>
                  <Col md={6} id="back-button">
                    <button
                      className="btn btn-light rounded-pill px-3 px-md-4 float-md-right mt-1"
                      onClick={history.goBack}
                    >
                      Back
                    </button>
                  </Col>
                </Row>
              </Container>
            </Container>

            {/* ----------------------doctor- profile----------------------- */}
            <Container fluid className="dr-profile mt-5">
              <h2 className="text-center mb-5">PROFILE</h2>
              <Row>
                <Col md={5}>
                  <div className="half-circle">
                    <div className="doctor-pic-circle">
                      <img src={drimg} alt="" />
                    </div>
                  </div>
                </Col>
                <Col md={7} id="doc-detail">
                  <h1 className="">{doctorname && doctorname[0].Name}</h1>
                  <ul>
                    <li>
                      <span>Qualification</span>
                      <span>{doctorname && doctorname[0].Qualification}</span>
                    </li>
                    <li>
                      <span>Specialization</span>
                      <span>{doctorname && doctorname[0].Specialisation}</span>
                    </li>
                    <li>
                      <span>Designation</span>
                      <span>{doctorname && doctorname[0].Designation}</span>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default StepThird;
