export const incNumber = () => {
  return {
    type: "INCREMENT",
  };
};
export const decNumber = (abc) => {
  // console.log(abc);
  return {
    type: "DECCREMENT",
    data: abc,
  };
};

export const doctorDetails = (fullDocotrDetails) => {
  return {
    type: "DOCTORDETAILS",
    data: fullDocotrDetails,
  };
};

export const userMobileNumberInput = (mno) => {
  return {
    type: "MOBILENUMBER",
    data: mno,
  };
};

export const appointmentTime = (apntTime) => {
  return {
    type: "APPOINTMENT_TIME",
    data: apntTime,
  };
};

export const appointmentDateByUser = (apntDate) => {
  return {
    type: "APPOINTMENT_DATE",
    data: apntDate,
  };
};

export const userRequestedOtp = (userOTP) => {
  return {
    type: "OTP",
    data: userOTP,
  };
};

export const userFullInformation = (patientInformation) => {
  return {
    type: "PATIENT_INFO",
    data: patientInformation,
  };
};

export const updatedUserFullInformation = (patientInformation) => {
  return {
    type: "PATIENT_INFO_ALL",
    data: patientInformation,
  };
};

export const resetValueLogout = (logout) => {
  return {
    type: "LOGOUT_REQUEST",
    data: logout,
  };
};

// export const doctorAvailableSlots = (slots) => {
//   return {
//     type: "DOCTOR_AVAILABLE_SLOTS",
//     data: slots,
//   };
// };
