import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import UserContextProvider from "./UserContext";
import { Provider } from "react-redux";
import Store from "./Store.js";

ReactDOM.render(
  <Router>
    <UserContextProvider>
      <Provider store={Store}>
        <App />
      </Provider>
    </UserContextProvider>
  </Router>,
  document.getElementById("root")
);

reportWebVitals();
