import { Route, Switch } from "react-router-dom";
import StepFirst from "./components/StepFirst";
import StepSecond from "./components/StepSecond";
import Test from "./components/Test";

import StepThird from "./components/StepThird";
import StepFour from "./components/StepFour";
import StepSix from "./components/StepSix";
import StepSeven from "./components/StepSeven";
import Register from "./components/Register";
import FinalStep from "./components/FinalStep";
import PrivateRoute from "./service/PrivateRoute";
import TestAPI from "./components/TestAPI";
import { useSelector } from "react-redux";
import BackUp from "./components/BackUp";
const App = () => {
  const myState = useSelector((state) => state.changeTheNumber);

  return (
    <>
      <Switch>
        <Route exact path="/" component={StepFirst} />
        {/* <Route path="/" component={BackUp} /> */}

        <Route
          exact
          path="/step-second/doctorid/:doctorid"
          component={StepSecond}
        />
        {/* <Route exact path="/step-test/doctorid/:doctorid" component={Test} /> */}
        <Route exact path="/test" component={Test} />
        {myState.length !== 0 ? (
          <Route
            exact
            path="/step-third/doctorid/:doctorid"
            component={StepThird}
          />
        ) : (
          <Route exact path="/" component={StepFirst} />
        )}
        <Route exact path="/done-final" component={FinalStep} />

        {myState.length !== 0 ? (
          <Route
            exact
            path="/step-four/doctorid/:doctorid"
            component={StepFour}
          />
        ) : (
          <Route exact path="/" component={StepFirst} />
        )}

        {/* <PrivateRoute path="/onlyAuthorizedAllowedHere/" component={MyComponent} /> */}

        {myState.length !== 0 ? (
          <PrivateRoute
            exact
            path="/step-six/doctorid/:doctorid"
            component={StepSix}
          />
        ) : (
          <Route exact path="/" component={StepFirst} />
        )}

        {myState.length !== 0 ? (
          <PrivateRoute
            exact
            path="/register/doctorid/:doctorid"
            component={Register}
          />
        ) : (
          <Route exact path="/" component={StepFirst} />
        )}

        {myState.length !== 0 ? (
          <PrivateRoute exact path="/step-seven" component={StepSeven} />
        ) : (
          <Route exact path="/" component={StepFirst} />
        )}

        <Route exact path="/myapi-test" component={TestAPI} />
        <Route component={StepFirst} />
      </Switch>
      {/* <All /> */}
    </>
  );
};

export default App;
