import React from "react";
// import axios from "axios";
const TestAPI = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    const FormData = require("form-data");
    //   let data = new FormData();
    let data = new FormData();
    data.append("Phone", `${e.target[1].value}`);
    data.append("City", `${e.target[2].value}`);
    data.append("Email", `${e.target[3].value}`);
    data.append("Name", `${e.target[0].value}`);
    data.append("Details", `${e.target[4].value}`);

    //  let config = {
    //    method: "post",
    //    url: "https://teleduce.corefactors.in/lead/apiwebhook/dce36b40-d15b-4af8-bb9b-f6413b978af1/Website/",
    //    headers: {},
    //    data: data,
    //  };

    //  axios(config)
    //    .then(({ data }) => {
    //      console.log(data.response);
    //    })
    //    .catch((error) => {
    //      console.log(error);
    //    });
    //  e.preventDefault();
    //  const name = document.getElementById("name").value;
    //  const mobileNumber = document.getElementById("mobileNumber").value;
    //  const emails = document.getElementById("email").value;
    //  const city = document.getElementById("city").value;
    //  const detail = document.getElementById("details").value;
    //  console.log(name, mobileNumber, emails, city, detail);
    //  alert(name, mobileNumber, emails, city, detail);

    //  let formdata = new FormData();

    //  formdata.append("Phone", `${mobileNumber}`);
    //  formdata.append("City", `${city}`);
    //  formdata.append("Email", `${emails}`);
    //  formdata.append("Name", `${name}`);
    //  formdata.append("Details", `${detail}`);

    const requestOptions = {
      method: "POST",
      body: data,
      redirect: "follow",
    };
    fetch(
      "https://teleduce.corefactors.in/lead/apiwebhook/dce36b40-d15b-4af8-bb9b-f6413b978af1/Website/",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        if (result.split('"')[15] === "success")
          window.location =
            "https://digikit.in/cutis-contact-form/itcs-contact-page/save.php";
        else {
          alert(`Please try after some time`);
          window.location =
            "https://itcslimited.com/study-mbbs-abroad/thank-you/";
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      <div className="container my-5">
        <div className="row">
          <div className="col-md-10 text-center">
            <form
              onSubmit={handleSubmit}
              //   action="https://digikit.in/cutis-contact-form/itcs-contact-page/save.php"
              method="POST"
            >
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Name"
                />
              </div>
              <div className="mb-3">
                <input
                  type="tel"
                  minLength="10"
                  maxLength="10"
                  className="form-control"
                  id="mobileNumber"
                  name="mobileNumber"
                  placeholder="Mobile Number"
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="city"
                  name="city"
                  placeholder="city"
                />
              </div>
              <div className="mb-3">
                <input
                  type="mail"
                  className="form-control"
                  id="mailid"
                  name="mailid"
                  placeholder="name@example.com"
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="details"
                  name="details"
                  placeholder="details"
                />
              </div>
              <div className="mb-3">
                <button className="btn btn-success">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestAPI;
