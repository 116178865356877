const initialState = [];

// const doctorDetails = ""
// const appointmentTime = ""
// const appointmentDate = ""
// const mobileNumber = ""
// const userOTP = ""

const changeTheNumber = (state = initialState, action) => {
  switch (action.type) {
    case "DOCTORDETAILS":
      return [...state, action.data];

    case "MOBILENUMBER":
      return [...state, action.data];

    case "OTP":
      return [...state, action.data];

    case "APPOINTMENT_TIME":
      return [...state, action.data];

    case "APPOINTMENT_DATE":
      return [...state, action.data];

    case "PATIENT_INFO":
      return [...state, action.data];

    case "PATIENT_INFO_ALL":
      return [...state, action.data];

    case "LOGOUT_REQUEST":
      return [action.data];

    // case "DOCTOR_AVAILABLE_SLOTS":
    //   return [...state, action.data];

    default:
      return state;
  }
};

export default changeTheNumber;
