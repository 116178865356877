import React, { useRef, useState, useContext } from "react";
import "./StepFour.css";
import { Container, Col, Row, Modal, Button } from "react-bootstrap";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../UserContext";
import { useSelector, useDispatch } from "react-redux";
import { userMobileNumberInput, userRequestedOtp } from "../actions/Index";

const StepFour = (props) => {
  const myState = useSelector((state) => state.changeTheNumber);
  const dispatch = useDispatch();

  const uri = `${process.env.REACT_APP_URL}`;

  const getdoctorID = props.match.params.doctorid;
  const { updateUser } = useContext(UserContext);
  const getDocotr = JSON.parse(myState[0]);
  const appointmentDate = myState[1];
  const history = useHistory();
  const textInput = useRef();
  const otpInput1 = useRef();
  const otpInput2 = useRef();
  const otpInput3 = useRef();
  const otpInput4 = useRef();
  const [getOtp, setgetOtp] = useState("");
  const [state, setstate] = useState(true);
  const [userMobileNumber, setuserMobileNumber] = useState(0);
  const [errorMessage, seterrorMessage] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [datestring, setDateString] = useState("");

  const openModal = () => {
    handleShow(true);
  };

  const userOtpRequest = (dateStr, mobileNumber) => {
    axios
      .get(
        `${uri}/Doctor/GenerateOTP?hospitalid=1&date=${dateStr}&PhoneNum=${mobileNumber}`
      )
      .then(({ data }) => {
        let temp = JSON.parse(data.Data);
        // console.log(JSON.stringify(temp));
        setstate(false);
        otpInput1.current.value = "";
        otpInput2.current.value = "";
        otpInput3.current.value = "";
        otpInput4.current.value = "";
        setgetOtp(temp);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const handleSubmit = (e) => {
    const mobileNumber = parseInt(textInput.current.value);
    setuserMobileNumber(mobileNumber);
    dispatch(userMobileNumberInput(mobileNumber));

    const otpDate = appointmentDate.split("/");

    const dateStr = `${otpDate[2]}-${otpDate[1]}-${otpDate[0]}`;

    setDateString(dateStr);
    userOtpRequest(dateStr, mobileNumber);
    e.preventDefault();
  };
  let otpValue = "";
  const handleSubmitOTP = (e) => {
    for (let i = 0; i < 4; i++) {
      otpValue += e.target[i].value;
    }
    if (parseInt(getOtp[0].OTP) === parseInt(otpValue)) {
      dispatch(userRequestedOtp(otpValue));
      axios
        .get(`${uri}/Patient/GetPatientDetails?MobileNum=${userMobileNumber}`)
        .then((response) => {
          if (response.data.Data) {
            sessionStorage.setItem("isAuthenticated", true);
          }
          if ("Patient Details" === response.data.Message) {
            updateUser(true, []);
            history.push(`/step-six/doctorid/${getdoctorID}`);
          } else {
            history.push(`/register/doctorid/${getdoctorID}`);
          }
        })
        .catch((err) => {
          console.warn(err);
        });
      e.preventDefault();
    } else {
      seterrorMessage(`Wrong OTP`);
      openModal();
      e.preventDefault();
    }
  };
  const changeMystate = () => {
    seterrorMessage(`OTP Sent to your mobile Number ${userMobileNumber}`);
    openModal();
    userOtpRequest(datestring, userMobileNumber);
  };
  const handleKey = (evt) => {
    if (otpInput1.current.value.length === 1) {
      otpInput2.current.focus();
    }
  };
  const handleKey2 = (evt) => {
    if (otpInput2.current.value.length === 1) {
      otpInput3.current.focus();
    }
  };
  const handleKey3 = (evt) => {
    if (otpInput3.current.value.length === 1) {
      otpInput4.current.focus();
    }
  };
  function setInputFilter(textbox, inputFilter) {
    [
      "input",
      "keydown",
      "keyup",
      "mousedown",
      "mouseup",
      "select",
      "contextmenu",
      "drop",
    ].forEach(function (event) {
      if (textbox) {
        textbox.addEventListener(event, function () {
          if (inputFilter(this.value)) {
            this.oldValue = this.value;
            this.oldSelectionStart = this.selectionStart;
            this.oldSelectionEnd = this.selectionEnd;
          } else if (this.hasOwnProperty("oldValue")) {
            this.value = this.oldValue;
            this.setSelectionRange(
              this.oldSelectionStart,
              this.oldSelectionEnd
            );
          } else {
            this.value = "";
          }
        });
      }
    });
  }
  setInputFilter(document.getElementById("intTextBox"), function (value) {
    return /^-?\d*$/.test(value);
  });
  return (
    <div id="main-div-fluid">
      {/* ----------------upper strip---------------- */}
      <Container fluid id="upper-strip">
        <Container className="pt-4 pb-md-0 pb-3">
          <Row>
            <Col md={6} id="doctor-detail" className="d-flex">
              <AddCircleIcon id="step-third-icon" />
              <div className="text-white">
                <h4>{getDocotr && getDocotr[0].Name}</h4>
                <p>
                  {getDocotr && getDocotr[0].Specialisation}&nbsp;&
                  <br />
                  {getDocotr && `${getDocotr[0].Designation}`}
                </p>
              </div>
            </Col>
            <Col md={6} id="back-button">
              <button
                className="btn btn-light rounded-pill px-3 px-md-4 float-right mt-1"
                onClick={history.goBack}
              >
                Back
              </button>
              {/* {getOtp} */}
            </Col>
          </Row>
        </Container>
      </Container>
      {/* ------------------------login/signup------------------------ */}

      <Container>
        <Row className="login-row">
          {state ? (
            <Col
              md={6}
              className="login-box order-1 order-md-0 d-flex justify-content-center"
            >
              <div className="login-sign-box text-center text-white py-sm-5">
                <i className="fas fa-user"></i>
                <h3 className="py-1">
                  <span>Login / Signup</span>
                </h3>
                <p className="mt-sm-5 mt-3">Enter Your Mobile Number</p>
                <form onSubmit={handleSubmit}>
                  <div className="d-flex">
                    <input
                      className="inp-1 rounded-pill py-2 mb-4"
                      type="text"
                      value="+91"
                      disabled
                    />
                    <input
                      className="inp-2 rounded-pill px-sm-5 py-2  text-center mb-4"
                      id="intTextBox"
                      maxLength="10"
                      name="phone"
                      pattern="[6-9]{1}[0-9]{9}"
                      required
                      ref={textInput}
                    ></input>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-md btn-primary rounded-pill px-3 px-md-4 mt-sm-5"
                    id="rounded-pill"
                  >
                    Get&nbsp;OTP&nbsp;&gt;
                  </button>
                </form>
              </div>
            </Col>
          ) : (
            <Col
              md={6}
              className="login-box order-1 order-md-0 d-flex justify-content-center"
            >
              <div className="login-sign-box text-center text-white py-sm-5">
                <i className="fas fa-user"></i>
                <h3 className="py-1">Login</h3>
                {/* {<small>`OTP sent to this ******${userMobileNumber.slice(6, 10)}`</small>} */}
                <p className="mt-4">Enter OTP</p>
                <form action="" onSubmit={handleSubmitOTP}>
                  <div className="d-flex justify-content-center ">
                    <input
                      className=" otp text-center rounded mx-2 py-2 mb-4"
                      type="number"
                      placeholder="*"
                      maxLength="1"
                      required
                      pattern="[0-9]{1}"
                      autoFocus
                      onKeyUp={handleKey}
                      ref={otpInput1}
                    />
                    <input
                      className=" otp text-center rounded mx-2 py-2 mb-4"
                      type="number"
                      placeholder="*"
                      maxLength="1"
                      required
                      pattern="[0-9]{1}"
                      onKeyUp={handleKey2}
                      ref={otpInput2}
                    />
                    <input
                      className=" otp text-center rounded mx-2 py-2 mb-4"
                      type="number"
                      placeholder="*"
                      maxLength="1"
                      required
                      pattern="[0-9]{1}"
                      onKeyUp={handleKey3}
                      ref={otpInput3}
                    />
                    <input
                      className=" otp text-center rounded mx-2 py-2 mb-4"
                      type="number"
                      placeholder="*"
                      maxLength="1"
                      required
                      pattern="[0-9]{1}"
                      ref={otpInput4}
                    />
                  </div>
                  <small>
                    Didn't recieve OTP?
                    <span className="text-danger hover-me">
                      <strong onClick={changeMystate}>&nbsp;Resend it</strong>
                    </span>
                  </small>
                  <br />

                  <button
                    type="submit"
                    className="btn btn-md btn-primary rounded-pill px-3 px-md-4 my-sm-2"
                    id="get-otp"
                  >
                    Verify&nbsp;&&nbsp;Proceed
                  </button>
                </form>
              </div>
            </Col>
          )}
          <Col md={6} className=" text-center mb-4 tip-box order-0 order-md-1">
            <h2>
              Login&nbsp;To
              <br />
              Complete&nbsp;The
              <br />
              Appointment&nbsp;Booking
            </h2>
          </Col>
        </Row>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Success !!!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-danger">{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StepFour;
