// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "./second.css";
// import { Link, useHistory } from "react-router-dom";
// import AddCircleIcon from "@material-ui/icons/AddCircle";
// import { Modal, Button } from "react-bootstrap";
// const $ = window.$;

// function Test(props) {
//    React.useEffect(() => {
//       axios
//          .get("https://localhost:44329/Common/getallcities")
//          .then(({ data }) => {
//             console.log(data);
//          })
//          .catch((error) => {
//             console.log(error);
//          });
//    }, [])

//    const [errorMessage, seterrorMessage] = useState(null);
//    const [buttonValue, setButtonValue] = useState([]);
//    const [appointmentDate, setAppointmentDate] = useState();
//    const [appTime, setappTime] = useState();
//    const history = useHistory();
//    const [doctorname, setDoctorname] = useState("");
//    const [sessionDateState, setSessionDateState] = useState("")
//    const getdoctorID = props.match.params.doctorid;
//    const uri = `${process.env.REACT_APP_URL}`;

//    useEffect(() => {
//       axios
//          .get(
//             `${uri}/Doctor/GetDoctorProfile?DoctorId=${getdoctorID}&hospitalid=1`
//          )
//          .then(({ data }) => {
//             const temp = JSON.parse(data.Data);
//             sessionStorage.setItem("docinfo", JSON.stringify(temp));
//             setDoctorname(temp);
//          })
//          .catch((err) => {
//             console.warn(err);
//          });
//    }, [getdoctorID, uri]);

//    const getTimeSlot = (apiDate, datestrOutput) => {
//       axios
//          .get(
//             `${uri}/Doctor/GetDoctorAppointments?hospitalid=1&Doc_Id=${getdoctorID}&date=${apiDate}`
//          )
//          .then(({ data }) => {
//             if (data.SlotInfo) {
//                let temp = JSON.parse(data.SlotInfo);
//                for (let i = 0; i < temp.length; i++) {
//                   setButtonValue(currentTime => [...currentTime, temp[i]]);
//                   if (temp[i].FromTime === "06:50 PM") {
//                      break;
//                   }
//                }
//             } else {
//                setButtonValue([]);
//                seterrorMessage(`No time slot for this Date : ${datestrOutput}`);
//                openModal();
//             }
//          })
//          .catch((err) => {
//             console.error(err);
//          });
//    };

//    const callMePLZ = (e) => {
//       buttonValue.map((value, index) => {
//          document.getElementById(`${index}`).classList.remove("active");
//          setappTime(e.target.value);
//          return e.target.classList.add("active");
//       });
//    };

//    const handleSubmit = (e) => {
//       sessionStorage.setItem("AppointmentDate", sessionDateState);
//       if (appTime === undefined) {
//          seterrorMessage(
//             `You havn't selected booking time and date`
//          );
//          openModal();
//          e.preventDefault();
//       }
//       else {
//          seterrorMessage(
//             `You are booikng appointment at ${sessionDateState} and time is ${appTime}.`
//          );
//          openModal();
//          history.push(`/step-four/doctorid/${getdoctorID}`);
//          sessionStorage.setItem("AppointmentTime", appTime);
//       }
//    };

//    const visitLink = `/step-third/doctorid/${getdoctorID}`;
//    const [show, setShow] = useState(false);

//    const handleClose = () => setShow(false);
//    const handleShow = () => setShow(true);

//    const openModal = () => {
//       handleShow(true);
//    };

//    $("#datepicker").change(function () {
//       const myNam = $(this).val();
//       const temp = myNam.split("/");
//       const apiDate = temp[2] + "-" + temp[0] + "-" + temp[1];
//       const datestrOutput = temp[1] + "/" + temp[0] + "/" + temp[2];
//       const date = temp[1] + "-" + temp[0] + "-" + temp[2];
//       setAppointmentDate(date);
//       setSessionDateState(datestrOutput);
//       getTimeSlot(apiDate, datestrOutput);
//    });
//    $(function () {
//       let disableDate = [];
//       axios
//          .get(`${uri}/Doctor/GetDoctorDetails?hospitalid=1`)
//          .then(({ data }) => {
//             console.log(data.Availability.Days)
//             const dateshow = JSON.parse(data.Leaves);
//             for (let i = 0; i < dateshow.length; i++) {
//                if (dateshow[i].DoctorId === getdoctorID) {
//                   const tempdata = dateshow[i].FromDate.split("/");
//                   console.log(dateshow[i].FromDate)
//                   disableDate.push(tempdata[1]);
//                }
//             }
//          })
//          .catch((err) => {
//             console.warn(err);
//          });
//       $("#datepicker").datepicker(
//          {
//             beforeShowDay: function (d) {
//                var day = d.getDate();
//                if (disableDate.length === 2)
//                   return [(day !== parseInt(disableDate[0]) && day !== parseInt(disableDate[1]))];
//                else if (disableDate.length === 3)
//                   return [(day !== parseInt(disableDate[0]) && day !== parseInt(disableDate[1]) && day !== parseInt(disableDate[2]))];
//                else if (disableDate.length === 4)
//                   return [(day !== parseInt(disableDate[0]) && day !== parseInt(disableDate[1]) && day !== parseInt(disableDate[2]) && day !== parseInt(disableDate[3]))];
//                else if (disableDate.length === 5)
//                   return [(day !== parseInt(disableDate[0]) && day !== parseInt(disableDate[1]) && day !== parseInt(disableDate[2]) && day !== parseInt(disableDate[3]) && day !== parseInt(disableDate[4]))];
//                else if (disableDate.length === 6)
//                   return [(day !== parseInt(disableDate[0]) && day !== parseInt(disableDate[1]) && day !== parseInt(disableDate[2]) && day !== parseInt(disableDate[3]) && day !== parseInt(disableDate[4]) && day !== parseInt(disableDate[5]))];
//             }
//          }
//       );
//    });

//    return (
//       <>
//          <div id="main-div-fluid">
//             <div className="container-fluid text-white bgme pt-2">
//                <div className="container">
//                   <div className="row align-items-center">
//                      <div className="col-sm-9">
//                         <div className="row">
//                            <div className="mx-2 my-1  step-third-icon">
//                               <AddCircleIcon id="step-second-icon" />
//                            </div>
//                            <div className="text-white" id="smallText">
//                               <h4>{doctorname && doctorname[0].Name}</h4>
//                               <p>
//                                  {doctorname && `${doctorname[0].Specialisation}`}&nbsp;&
//                                  <br />
//                                  {doctorname && `${doctorname[0].Designation}`}
//                               </p>
//                            </div>
//                         </div>
//                      </div>
//                      <div className="col-sm-3">
//                         <div className="row align-items-center">
//                            <div className="button mx-auto">
//                               <Link to={visitLink} className="text-white">
//                                  View Profile
//                               </Link>
//                               &nbsp; &nbsp;<i className="fas fa-user"></i>
//                            </div>
//                            <div className="button ml-auto" id="back-button">
//                               <button
//                                  className="btn btn-light rounded-pill px-3 px-md-4 float-md-right my-1"
//                                  onClick={history.goBack}
//                               >
//                                  Back
//                               </button>
//                            </div>
//                         </div>
//                      </div>
//                   </div>
//                </div>
//             </div>
//             <div className="container p-sm-2 mt-sm-5 ">
//                <form onSubmit={handleSubmit}>
//                   <div className="row justify-content-around">
//                      <div
//                         className="col-md-5 text-center card p-sm-4 mt-sm-0 mt-2"
//                         id="choose-time-box"
//                      >
//                         <h3 className=" pt-sm-0 pt-3">Choose date</h3>
//                         <hr />
//                         <input type="text" id="datepicker" placeholder="Select date" required readOnly={true} />
//                      </div>
//                      {buttonValue.length !== 0 ? (
//                         <div className="col-md-7 text-center mt-sm-0 mt-2">
//                            <div className="row card mx-md-2 p-sm-4" id="Specificheight">
//                               <h3 className="heading">Choose Slot</h3>
//                               <hr />
//                               <div className="another-div">
//                                  {buttonValue.map((value, index) => {
//                                     return (
//                                        <div className="d-inline" key={index}>
//                                           {value.SlotStatus === "Available" ? (
//                                              <div
//                                                 className="btn-group"
//                                                 role="group"
//                                                 aria-label="Third group"
//                                              >
//                                                 <input
//                                                    type="button"
//                                                    value={`${value.FromTime} - ${value.ToTime}`}
//                                                    id={index}
//                                                    onClick={callMePLZ}
//                                                    className="btn btn-outline-success m-sm-2 m-1"
//                                                 />
//                                              </div>
//                                           ) : (
//                                              <div
//                                                 className="btn-group"
//                                                 role="group"
//                                                 aria-label="Third group"
//                                              >
//                                                 <input
//                                                    type="button"
//                                                    id={index}
//                                                    value={`${value.FromTime} - ${value.ToTime}`}
//                                                    className="btn btn-danger m-1"
//                                                    disabled
//                                                 />
//                                              </div>
//                                           )}
//                                        </div>
//                                     );
//                                  })}
//                               </div>
//                            </div>
//                         </div>
//                      ) : null}
//                   </div>
//                   <div className="row">
//                      <div
//                         className="col-12 text-center my-2 my-sm-4"
//                         id="step-second-button-row"
//                      >
//                         {appointmentDate !== undefined && appTime !== undefined ? (
//                            <div className="d-flex justify-content-center">
//                               <div className=" col-md-8 text-white show-book-time">
//                                  <h5 className="mt-2">
//                                     {appointmentDate === "" && appTime === ""
//                                        ? setAppointmentDate(
//                                           "Select date and time for Appointment"
//                                        )
//                                        : `Your Appointment Date is ${appointmentDate}, and time is ${appTime}`}
//                                  </h5>
//                               </div>
//                            </div>
//                         ) : (
//                            appTime
//                         )}
//                         <div className="mt-2 mt-sm-4">
//                            <button className="btn btn-success btn-lg">
//                               Proceed to Book
//                            </button>
//                         </div>
//                      </div>
//                   </div>
//                </form>
//             </div>
//          </div>
//          <Modal
//             show={show}
//             onHide={handleClose}
//             backdrop="static"
//             keyboard={false}
//          >
//             <Modal.Header closeButton>
//                <Modal.Title className="text-danger">Warning !!!</Modal.Title>
//             </Modal.Header>
//             <Modal.Body className="text-danger">{errorMessage}</Modal.Body>
//             <Modal.Footer>
//                <Button className="btn btn-danger" onClick={handleClose}>
//                   Okay
//                </Button>
//             </Modal.Footer>
//          </Modal>
//       </>
//    );
// }
// export default Test;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { incNumber, decNumber } from "../actions/Index";

const Test = () => {
  const myState = useSelector((state) => state.changeTheNumber);
  const dispatch = useDispatch();
  // console.log(myState);
  const myName = "Ankit Saurabh";
  return (
    <div>
      <div className="container">
        <h1>Increment and Decerement Counter</h1>
        <h4>Using react and redux</h4>
        <div className="quantity">
          <button
            title="Decerement"
            className="quantity_minus"
            onClick={() => dispatch(decNumber(myName))}
          >
            <span>-</span>
          </button>
          <input
            type="text"
            name="quantity"
            className="input_quantity"
            value={myState}
            readOnly={true}
          />
          <button
            title="Increment"
            className="quantity_plus"
            onClick={() => dispatch(incNumber())}
          >
            <span>+</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Test;
